import React from 'react';

import { SanityImage } from '@components/sanity/sanityImage';
import { ModuleWrapper } from '../moduleWrapper';
import ImageModal from '@components/imageModal';
import EnquiryForm from '@components/enquiryForm';

const GridItem = ({ icon, title, description, _key, imageModal, enquireLinkText, linkType }) => {
  return (
    <div key={_key} className="text-center md:w-1/2 md:px-16 lg:w-1/3">
      <div className=" w-16 mx-auto relative">
        <div className="aspect-w-1 aspect-h-1 w-full">
          <SanityImage image={icon} className="absolute w-full h-full object-contain" />
        </div>
      </div>
      <h4 className="blockH3 my-half font-bold">{title}</h4>
      <p>{description}</p>
      {linkType === 'enquire' && (
        <div className="mt-half">
          <EnquiryForm
            linkText={enquireLinkText || 'Enquire Now'}
            style="text"
            enquiredFrom={title}
          />
        </div>
      )}
      {linkType === 'image' && <ImageModal {...imageModal} linkText={enquireLinkText || "Read More"}/>}
    </div>
  );
};

const IconGrid = ({ title, items, config }) => {
  return (
    <ModuleWrapper {...config}>
      <div className="px-gutter">
        <h2 className="blockH2 text-red text-center mb-three max-w-3xl mx-auto">{title}</h2>
        {/* Grid */}
        <div className="flex flex-col gap-y-10 md:flex-row md:flex-wrap md:gap-y-16 lg:justify-center">
          {items.map((item) => (
            <GridItem {...item} />
          ))}
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default IconGrid;
