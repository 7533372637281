import React, { useState } from 'react';
import cx from 'classnames';

import { SanityImage } from '@components/sanity/sanityImage';
import Close from '@svg/close.svg';
import PortalModal from '@components/ui/portalModal';

const ImageModal = ({ linkText, image, title, style = 'text' }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <button
        className={cx({
          'btn-solid-red': style === 'solid',
          'btn-red': style === 'outline',
          'text-link': style === 'text',
        })}
        onClick={() => setOpen(true)}
      >
        {linkText || 'More Info'}
      </button>
      <PortalModal isShowing={isOpen}>
        <div className="px-gutter self-start w-full my-10">
          <div className="p-10 max-w-6xl w-full bg-red-light rounded-xl mx-auto relative lg:px-20 lg:py-16">
            <button className="absolute top-4 right-4" onClick={() => setOpen(false)}>
              <Close className="w-16 text-red fill-current" />
            </button>
            <h2 className="blockH2 text-center text-red mb-one">{title}</h2>
            {image && <SanityImage image={image} />}
          </div>
        </div>
      </PortalModal>
    </>
  );
};

export default ImageModal;
